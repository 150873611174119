import http from '@/utils/http';


export const getSpells = (parameter) => {
  return http.request({
    url: '/v1/relation/games/spell/list',
    method: 'get',
    params: parameter
  })
}
